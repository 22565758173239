/**
 * @license
 * MyFonts Webfont Build ID 3867246, 2020-12-16T11:57:38-0500
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are 
 * explicitly restricted from using the Licensed Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 * 
 * Webfont: undefined by undefined
 * URL: https://www.myfonts.comundefined
 * Copyright: Copyright © 2023 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: undefined by undefined
 * URL: https://www.myfonts.comundefined
 * Copyright: Copyright © 2023 Monotype Imaging Inc. All rights reserved.
 *
 * Webfont: undefined by undefined
 * URL: https://www.myfonts.comundefined
 * Copyright: Copyright © 2023 Monotype Imaging Inc. All rights reserved.
 * 
 * © 2023 MyFonts Inc. */

@font-face
    font-family: FFScalaSans
    src: url('../assets/fonts/ScalaSansProLight/font.woff2') format('woff2'), url('../assets/fonts/ScalaSansProLight/font.woff') format('woff')
    font-weight: 100
    font-display: auto

@font-face
    font-family: FFScalaSans
    src: url('../assets/fonts/ScalaSansProRegular/font.woff2') format('woff2'), url('../assets/fonts/ScalaSansProRegular/font.woff') format('woff')
    font-weight: 400
    font-display: auto

@font-face
    font-family: FFScalaSans
    src: url('../assets/fonts/ScalaSansProBold/font.woff2') format('woff2'), url('../assets/fonts/ScalaSansProBold/font.woff') format('woff')
    font-weight: 700
    font-display: auto
