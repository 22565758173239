$base-font: FFScalaSans

// Colors
$primary-color: #2b2b2b
$secondary-color: #536390
$font-color: #2b2b2b
$bg-color: #fff

//Font-Weight
$light: 100
$def: 400
$bold: 700

//Paddings
$pad-0: 0px
$pad-4: 4px
$pad-8: 8px
$pad-12: 12px
$pad-16: 16px
$pad-32: 32px
$pad-64: 64px

//Margins
$mar-0: 0px
$mar-4: 4px
$mar-8: 8px
$mar-12: 12px
$mar-16: 16px
$mar-32: 32px
$mar-64: 64px

/* Using SCSS variables to store breakpoints */
$breakpoint-tablet: 768px
$breakpoint-tablet: 768px
$breakpoint-tablet: 768px
$breakpoint-tablet: 768px

/* Shadows */
$img-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px
